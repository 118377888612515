const messages_en = {

  //Onboarding GUI
  profileSelectionPanelWelcome: "Welcome to the ID Issuer onboarding process!",
  profileSelectionPanelSelect: "Please select your profile.",
  profileSelectionPanelProducerImporter: "Producer/Importer",
  profileSelectionPanelOther: "Trading companies, logistics, laboratories",
  profileSelectionPanelAccredited: "Accredited external operator",
  companyData: "Company Data",
  taxAndLegalInformation: "Tax and Legal Information",
  languageSettings: "Language Settings",
  invoicingLanguage: "Please choose the language for your invoice",
  invoicingLanguageDescription: "Choose the language for your invoicing",
  billingAddressSameAsInvoice: "Use company address as invoice address.",
  forwardCaption: "Forward",
  finishCaption: "Finish",
  backCaption: "Back",
  accreditedEoIdCaption: "Accredited EO ID",
  secRepoWarning: "After pressing Forward button, please wait while EO-ID is confirmed by the Secondary Repository.",
  eoIdFailed: "The submitted EO-ID is not confirmed by the Secondary Repository. In case of doubts, please double check with your competent local ID Issuer.",
  unknownAccreditedEoid:"You entered an EO-ID that haven't been issued by a competent ID Issuer.",
  secRepoUnavailable: "Secondary Repository is currently unavailable. Please try again later.",
  termsAndConditions: "I accept Terms and Conditions",
  termsAndConditionsError: "Error while downloading Terms and Conditions",
  dataPrivacyStatement: "Data privacy statement (in German language)",
  registrationSuccessfulPanelLabel: "Your registration request has been received.<br>You will receive an email confirmation shortly.<br><br>If you do not receive any email from ID Issuer in the next 24 hours, <br>please contact our service desk at ",
  notificationWarning: "It is not possible to register at the moment.Please try again later.",
  notificationConflict: "Entered data contains information already present in the system.",
  verificationMessage: "Enter code you received in SMS message. <br><br>If you didn't receive any SMS message from ID Issuer, <br>please contact our service desk at ",
  verificationSuccessful: "Your data has been verified successfully. Soon you will get an email with next steps.<br>If you do not receive any email from ID Issuer in the next 24 hours, <br>please contact our service desk at ",
  verificationConflict: "Your data has already been verified. If you did not received any email from ID Issuer, please contact our service desk at Id-issuer@bdr.de",
  verificationError: "Entered data not valid. Please make sure you entered correct data and try again. If you have any problems, contact our service desk at Id-issuer@bdr.de",
  proofOfWork: "I'm not a robot",
  proofOfWorkError: "An error occurred while verifying",
  taxIdentificationWarning: 'Tax identification is missing. Please submit one of VAT, TIN or German Steuernummer',
  keepDataAndContinue: 'Keep data and continue',
  correctData: 'Correct data',
  addressValidationFailedMessage: 'The address entered could not be successfully verified and is therefore not accepted. To complete the registration please enter a valid address. You can be assisted by the "Search address" field.',
  automaticVatValidationFailedMessage: 'Your VAT ID could not be successfully checked. You now have the option to review the data relevant for the check Company name incl. legal form, address and VAT ID. To do so, select "Correct data". If you are sure that the entered data corresponds to the VAT registration, you can continue with "Keep data and continue". In this case, the registration will be performed, but you will not be able to order upUI for the time being. Please contact our service team at id-issuer@bdr.de to clarify the VAT information.',
  primaryReminderModalMessage: 'Please note that an additional registration with a Primary Repository of your choice is required for successful UI ordering.',
  duplicateEODetected: 'This economic operator has been already registered. If you forgot only the password for your access you can recover it with the "Forgot password" option in the login-form yourself. In all other cases please contact our service team by email id-issuer@bdr.de',
  closeButton: "CLOSE",
  //operations GUI specific warnings
  selectSearchType: "Please select search type.",
  enterSearchText: "Search input text field is empty.",
  eoDoesNotExists: "Economic operator for this input does not exist.",
  idOrderDoesNotExists: "Order for this Idissuer order ID does not exists.",
  buendeOrderDoesNotExists: "Buende order ID does not exists.",
  customOrderDoesNotExists: "Customer order ID does not exists.",
  foundItems: "Found",
  buendeOrder: "Buende order",
  customerOrder: "Customer order",
  pleaseSelect: "Please select one economic operator of ",
  forEntered: " for entered ",
  searchBy: 'Search by',
  producerImporterDescription:'Producers or importers ',
  producerImporterDescriptionLocation: 'based in Germany',
  OtherDescription:'All other economic operators of the tobacco marked (e.g. sales or laboratories) ',
  OtherDescriptionLocation:'based in Germany',
  AccreditedDescription:'Producers or importers with European headquarters ',
  AccreditedDescriptionLocation: 'outside Germany',

  //specific operations GUI:
  noOfEconomicOperator: "EO",

  //Impressum
  impressum: "Impressum",
  impressum_company: "Bundesdruckerei GmbH\n",
  impressum_street: "Kommandantenstraße 18\n",
  impressum_city: "10969 Berlin\n",
  // "\n" +
  // "\n" +
  impressum_one: "Represented by its managing directors:\n",
  impressum_two: "Dr.-Ing. Stefan Hofschen (CEO)\n",
  impressum_three: "Christian Helfrich (CFO)\n",
  impressum_four: "Chairman of the Supervisory Board: Dr. Kai Beckmann\n",
  impressum_five: "Registration court: Amtsgericht Berlin Charlottenburg (Magistrates' Court)\n",
  impressum_six: "Commercial register No.: HRB 80443\n",
  impressum_seven: "VAT registration No.: DE 813210005\n",
  // "\n" +
  impressum_contact: "Contact:\n",
  impressum_tel: "+49 (0)30 2598-0\n",
  impressum_fax: "Fax: +49 (0)30 2598-2205\n",
  // "\n" +
  impressum_email: "E-mail: info@bundesdruckerei.de\n",
  // "\n" +
  impressum_disclaimer: "Disclaimer\n",
  impressum_content_one: "Whilst Bundesdruckerei updates and supplements the information on its website to the best of its " +
    "knowledge, it does not, however, warrant that such information is up to date, correct or complete. " +
    "In view of the legal and technical situation on the Internet, Bundesdruckerei cannot assume any " +
    "warranty or liability for the correctness and completeness of the contents of the texts and the " +
    "information posted, its entry in machine-readable format, the electronic storage thereof in " +
    "databases, its dissemination, nor its compliance with data protection legislation and other " +
    "legal obligations by third parties.",
  // "\n\n" +
  impressum_content_two: "Please observe our user Information regarding the use of documents and information provided on " +
    "our website. Should you have any other questions regarding our website, please contact our " +
    "Internet Editorial Team.",

  support: "Support",
  supportMessage: "You can reach our call and support center by mail to id-issuer@bdr.de. Apart from that you can reach us by phone at the number +49(0)30 2598 -4044 from Monday to Sunday at any time. ",


  //Date
  dateYear: 'Year',
  dateMonth: 'Month',

  empty: ' ',
  valueNotAvailable: 'N/A',
  mandatoryFields: 'This field is mandatory. Please fill it out.',
  mandatoryFieldsPostalCode: "The field is mandatory to complete. In case no postal code has been assinged, please enter the value 'n/a'",
  emptyFields: 'Empty spaces not allowed.',
  repeatingLegalForm: "You must not enter the company's legal form in the field 'Company name without legal form'.",
  somethingWentWrong: 'Something went wrong. Please try again later',
  mapZoom: 'Please zoom and click again',

  goToFacilities: 'Go to Facilities',
  goToMachines: 'Go to Machines',
  maximumThreeOptions: 'Maximum filter options are three',
  pleaseFillInput: 'Search input is empty.',
  duplicateFilter: 'This filter is already added.',
  registeredFacilities: 'Registered Facilities',
  registeredMachines: 'Registered Machines',
  addressDoesNotExists: 'Address does not exist!',
  invalidAddress: 'Please insert a valid address.',
  selectAddress: 'Please select one option.',
  searchAddress: 'Search address',
  search: 'Search',
  insertIDfield: 'Input value is empty.',
  firstConfirm: 'Please first confirm!',
  failDeactivate: 'Account deactivation failed.',
  welcome: 'Welcome',
  deleteTIN: 'Delete TIN if you want to use VAT.',
  deleteVAT: 'Delete VAT if you want to use TIN.',
  emailSMScommunication: 'Language for email and SMS',
  emailSMScommunicationDescription: 'Choose the language used in communication from ID Issuer to you.',
  deLanguage: 'German',
  engLanguage: 'English',
  exportCsv: 'Export CSV',
  GEN_UNIT_UIDS: 'Unit Package UI',
  GEN_AGGRU_IDS: 'Aggregated UI',
  //++++++++++ BUTTONS:
  editButton: 'Edit',
  saveButton: 'Save',
  cancelCaption: 'Cancel',
  submitButtonCaption: 'Submit',
  //++++++++++ DASHBOARD:
  dashboardGridTitle: 'List of order activities',

  //++++++++++ MAIN HEADER:
  menuBarFacilities: 'Facilities',
  menuBarMachines: 'Machines',
  menuBarManagedRetailers: 'Managed retailers',
  menuBarOrders: 'Orders',
  menuBarAccountSettings: 'Account settings',
  defautlProducer: 'Producer',
  logOutButtonCaption: 'Log out',

  //++++++++++ GridComponents
  noDataCaption: 'No data to show',
  nameCaption: 'NAME',
  idCaption: 'ID',
  customerIdCaption: 'CUSTOMER ID',
  typeCaption: 'TYPE',
  fidCaption: 'FACILITY',
  midCaption: 'MACHINE',
  dateCaption: 'CREATED',
  date2Caption: ' DATE',
  quantityCaption: 'QUANTITY',
  upUICaption: 'Unit Package UI',
  aUICaption: 'Aggregated UI',
  upUIShortCaption: 'UpUi',
  aUIShortCaption: 'AUi',
  refreshButtonCaption: 'Reset',
  statusCaption: 'STATUS',
  detailsCaption: 'DETAILS',
  downloadCaption: 'DOWNLOAD',
  deliveryCaption: 'DELIVERY',
  //orderIdCaption: "ORDER ID",
  confirmationCaption: 'CONFIRMATION CODE',
  confirmationCaptionGrid: 'CONFIRMATION',
  addressCaption: 'ADDRESS',
  capacityCaption: 'CAPACITY',
  producerCaption: 'PRODUCER',
  modelCaption: 'MODEL',
  customerOrderIdHeaderCaption: 'YOUR ORDER NUMBER',
  endOfCancellationPeriodCaption: 'CANCELLATION UNTIL',

  //++++++++++ Address Form:
  addressFormCaption: 'Registered Address',
  invoiceFormCaption: 'Invoice Address',
  searchFieldCaption: 'Search address...',
  streetCaption: 'Street',
  streetDescription: 'Street name',
  houseNumberCaption: 'House number',
  houseNumberDescription: 'House number',
  addInfoCaption: 'Additional address infomation',
  addInfoDescription: 'Here you might enter supplementary information that helps to describe the facility location, e.g. "located in the 2nd floor"',
  zipCodeCaption: 'Postal code',
  zipCodeDescription:
    'Postal code, post code, PIN or ZIP Code is a series of letters or digits or both, sometimes including spaces or punctuation, included in a postal address for the purpose of sorting mail. The value "n/a" should be entered if the code does not exist.',
  cityCaption: 'City',
  cityDescription: 'City name',
  countryRegCaption: 'ISO Alpha-2 Country code',
  countryRegDescription:
    'Two-letter country codes defined in ISO 3166-1 standard to represent countries, dependent territories, and special areas of geographical interest.',
  countryCaption: 'Country of registration',
  countryDescription: 'The country the company resides',

  //++++++++++ LOGIN:
  usernameTextFieldCaption: 'Username',
  passwordTextFieldCaption: 'Password',
  logInButtonCaption: 'Log In',
  infoLabel: 'Wrong username or password!',
  passwordSuccessfullyChanged: 'Password is successfully changed.',
  passwordCouldNotChanged: 'It is not possible to change your password. Please try again later.',
  forgotPasswordLabel: 'This is a procedure if you forgot your password.',
  browserError: 'This browser is not supported.Please use some of the supported ones:',
  buttonClearCaption: 'Clear all',
  loginLabelTest:
    'This is a test environment,  no data that has been generated by this system can be used for productive operations.',
  labelTest:
    'This is a test environment,  no data that has been generated by this system can be used for productive operations.',

  //++++++++++ ACCOUNT SETTINGS:
  warningCaption: 'WARNING',
  warningMessage:
    'Are you sure you want to DEACTIVATE your account? All facilities and machines will be removed and pending orders canceled.',
  eoDeactivateCheckbox: 'I am sure.',
  resetCaption: 'Reset password',
  forgotPasswordCaption: 'Forgot your password?',
  nextCaption: 'Next',
  resetProcedureCaption: 'RESET PASSWORD PROCEDURE',
  resetInfoMessage:
    "If you click on 'Reset password' button and validation went successful, we will send you a new one time password via SMS on the mobile phone number you entered during the registration process.",
  wrongData: 'Data is not correct. Please check again.',
  //+++++ General settings:
  accountSettingsGeneralSettings: 'General settings',
  eoIdentifierCaption: 'EO identifier',
  eoIdentifierDescription: 'Economic operator identifier code',
  confirmationCodeCaption: 'Confirmation code',
  confirmationCodeDescription:
    'Economic operator confirmation code provided in response to the registration of economic operator',
  eoNameCaption: 'Company name without legal form',
  eoNameDescription: "Economic operator's registered name.",
  eoNameWithLegalForm: 'Registered name',
  eoAlternativeNameCaption: 'Alternative name',
  eoAlternativeNameDescription: "Economic operator's alternative or abridged name.",
  mobilePhoneCaption: 'Mobile phone',
  mobilePhoneDescription:
    'Mobile phone in the international format. Used to convey ID Issuer passwords.',
  eoEmailCaption: 'E-mail',
  eoEmailDescription:
    "Economic operator's email address used to inform about the registration process, including subsequent changes and other required correspondence.",
  vatNumberCaption: 'VAT',
  vatNumberDescription: "Economic operator's VAT number.",
  taxRegistrationNumberCaption: 'TIN',
  taxRegistrationNumberDescription: "Economic operator's tax registration number.",
  steuernummerCaption: 'German Steuernummer',
  steuernummerDescription: 'Economic operator German Steuernummer',
  exciseNumberCaption: 'Excise number',
  exciseNumberDescription:
    "Economic operator's excise number issued by the competent authority for the purpose of identification of persons/premises",
  otherEoIdNCaption: 'Other EOIDs',
  otherEoIdNDescription: 'EO identifier codes allocated by other ID Issuers',
  legalFormCaption: 'Legal form',
  legalFormDescription: 'Company\'s legal form',
  legalFormPlaceHolder: 'Please select legal form',
  legalFormNoCountry: 'Please select the country first',

  correctionWarning1: 'Old company address was the same as invoice address.',
  correctionWarning2: 'Do you want changes to be done on Invoice data too?',
  correctionYES: 'YES',
  correctionNO: 'NO',

  completeCompanyName: 'Complete company name',
  companyAbbreviation: 'Company abbreviation', //SHOULD NEVER BE SEEN
  //+++++ Invoicing:
  accountSettingsInvoice: 'Invoicing',
  invoiceCompanyCaption: 'Invoice company name',
  invoiceCompanyDescription: 'Invoice company name.',
  invoiceEmailCaption: 'Invoice E-mail',
  invoiceEmailDescription: 'E-mail address used for invoicing.',
  invoiceChangeMethod: 'Change method',
  invoiceChangeInvoiceMethod: 'Change invoice method',
  //+++++ Api Key:
  accountSettingsApiKey: 'API key',
  accountSettingsApiKeyInfo: 'Please save this data. After you close this view, all information will be lost.',
  accountSettingsApiKeyUsername: 'Username',
  accountSettingsApiKeyUsernameDescription: 'The API user name required for API usage.',
  accountSettingsApiKeyPassword: 'Password',
  accountSettingsApiKeyPasswordDescription: 'The API password required for API usage.',
  accountSettingsApiKeySecret: 'Secret',
  accountSettingsApiKeySecretDescription: 'The API secret required for API usage.',
  accountSettingsApiKeyNewKey: 'New key',
  //+++++ Change password:
  accountSettingsPasswordReset: 'Change password',
  changePasswordTitle: ' CHANGE ACCOUNT PASSWORD',
  changePasswordOldPassword: ' Old password',
  changePasswordNewPassword: ' New password',
  changePasswordConfirmPassword: ' Confirm password',
  changePasswordWarning1: ' Passwords do not match',
  changePasswordWarning2: ' New password must not be the same as username',
  changePasswordWarning3: ' Please insert a new password',
  changePasswordWarning4: ' Please confirm new password',
  changePasswordWarning5: ' Password length at least 8 characters',
  changePasswordWarning6: ' Digit is required',
  changePasswordWarning7: ' Lower Case letter is required',
  changePasswordWarning8: ' Upper Case letter is required',
  changePasswordWarning9: ' No white spaces allowed',
  changePasswordWarning10: ' Please insert an old password',
  notificationWrongOldPassword: 'Wrong old password. Please try again.',
  notificationPassCouldNotChange: 'It is not possible to change your password. Please try again later.',
  notificationPassChanged: 'Your password has been changed.',
  //+++++ Deactivate operator:
  accountSettingsDeregisterAccount: 'Deactivate account',
  deregisterEOFormCaption: 'Economic operator deactivation',
  deregisterEOButtonCaption: 'Deactivate economic operator',

  //++++++++++ FACILITY OPERATIONS:
  registerFacilityButtonCaption: 'Register facility',
  registerFacilityFormCaption: 'Facility registration',
  facilityCorrectionCaption: 'Facility correction',
  editFacilityButtonCaption: 'Edit facility',
  deregisterFacilityFormCaption: 'Facility deactivation',
  deregisterFacilityButtonCaption: 'Deactivate facility',
  facilityData: 'Facility data',
  filterBy: 'Filter by',
  myFacilities: 'My Facilities',
  noOfFacilities: 'Facilities',
  //+++++ Facility Registration:
  facilityStatus1: 'Facility has NO tax (excise) warehouse status',
  facilityStatus2: 'Facility has a tax (excise) warehouse status',
  facilityTypeCaption: 'Facility type',
  facilityTypeDescription: 'Type of facility',
  facilityTypeOtherCaption: 'Type other',
  facilityTypeOtherDescription: 'Description of Other facility type',
  facilityOtherFidNCaption: 'Other FIDs',
  facilityOtherFidNDescription: 'Facility identifier codes allocated by other ID Issuers',
  facilityOtherFidNPlaceholder:
    "Input other FIDs separated by commas. (e.g. '51q<$000000000025000020, 51q<$000000000025000021').",
  //+++++ Facility Correction:
  facilityIdentifierCaption: 'Facility Identifier',
  facilityIdentifierDescription: 'Facility identifier code',
  //+++++ Facility Delete:

  facilityIsRegisteredOnBehalfOfCaption: 'Is registered on behalf of',
  facilityIsRegisteredOnBehalfOfDescription:
    'Indication if the registration is made on behalf of a retail outlet operator not otherwise involved in the tobacco trade',
  facilityIsRegisteredOnBehalfOf_0: 'Registration is NOT done on behalf of a retail operator',
  facilityIsRegisteredOnBehalfOf_1: 'Registration is done on behalf of a retail operator',
  facilityEoIdentifierDescription: 'Economic Operator Identifier this facility is registered to',
  facilityType_1: 'Manufacturing site with warehouse',
  facilityType_2: 'Standalone warehouse',
  facilityType_3: 'Retail outlet',
  facilityType_4: 'Other',
  ManufacturingSiteWithWarehouse: 'MANUFACTURING',
  StandaloneWarehouse: 'WAREHOUSE',
  RetailOutlet: 'RETAIL',
  Other: 'OTHER',
  facilityStatusCaption: "Facility status 'tax warehouse'",
  facilityStatusDescription: 'Indication if a part of the facility has a tax (excise) warehouse status',
  facilityExciseNumber2Caption: 'Excise number',
  facilityExciseNumber2Description:
    'Facility excise number issued by the competent authority for the purpose of identification of persons/premises',
  listFacilitiesButtonCaption: 'List facilities',

  //++++++++++ MACHINE OPERATIONS:
  registerMachineFormCaption: 'Machine registration',
  registerMachineButtonCaption: 'Register machine',
  machineCorrectionCaption: 'Machine correction',
  editMachineButtonCaption: 'Edit machine',
  deregisterMachineButtonCaption: 'Deactivate machine',
  deregisterMachineFormCaption: 'Machine deactivation',
  machineData: 'Machine data',
  myMachines: 'My machines',
  noOfMachines: 'Machines',
  //+++++ Machine Registration:
  machineProducerCaption: 'Producer',
  machineProducerDescription: 'The machine used to produce the tobacco product, as set out by TPD.',
  machineModelCaption: 'Model',
  machineModelDescription: 'Machine model',
  machineSerialNumberCaption: 'Serial number',
  machineSerialNumberDescription: 'Machine serial number',
  machineCapacityCaption: 'Capacity',
  machineCapacityDescription: 'Maximum capacity over 24-hour production cycle expressed in unit packets',
  //+++++ Machine Correction:
  machineIdentifierCaption: 'Machine Identifier',
  machineIdentifierDescription: 'Machine Identifier of this facility',
  //+++++ Machine Delete:

  //++++++++++ ORDER OPERATIONS:
  myOrders: 'My Orders',
  noOfOrders: 'Orders',

  orderStatusStored: 'STORED',
  orderStatusInProcessing: 'IN PROCESSING',
  orderStatusReady: 'READY',
  orderStatusDelivered: 'DELIVERED',
  orderStatusClosed: 'CLOSED',
  orderStatusCanceled: 'CANCELED',
  orderStatusPending: 'PENDING AT SECONDARY REPOSITORY',
  orderStatusPendingHtml: 'PENDING AT SECONDARY REPOSITORY',
  orderStatusRejected: 'REJECTED BY SECONDARY REPOSITORY',
  orderStatusRejectedHtml: 'REJECTED BY SECONDARY REPOSITORY',
  //++++++++++ ORDER A_UI
  orderAUICaption: 'ORDER aUI',
  reqQuantityCaption: 'Request Quantity',
  reqQuantityAuiDescription: 'Requested quantity of individual identifiers at the package level',
  webHookBaseCaption: 'Web Hook Base',
  webHookBaseDescription:
    'Holds the base URL invoked with a GET operation once the UIs have been created. The base URL is extended with the OrderID as path parameter when invoked.',
  orderProcessingCaption: 'Order processing priority',
  orderProcessingPriorityCaption: 'PRIORITY',
  orderProcessing_1: 'STANDARD MODE',
  orderProcessing_2: 'IMMEDIATELY',
  binderValidatorOrderQuantity: 'Quantity must be a number between 1 and 1 000 000. Only numbers are allowed.',
  //++++++++++ ORDER UP_UI
  upUIOrderWithoutMachineLabel: 'This is for ordering unit package UIs without having a machine registered.',
  upUiOrderTitle: 'Up UI order',
  orderUpUIButtonCaption: 'ORDER upUI',
  tpBrandCaption: 'Brand',
  tpBrandDescription: 'Tobacco product brand',
  productWeightCaption: 'Package weight',
  productWeightDescription:
    'Average gross weight of the package, including packaging, in grams, with 0.1 gram accuracy. ',
  intendedMarketCaption: 'Intended market',
  intendedMarketDescription: 'Country intended for retail sale',
  intendedRoute2Caption: 'Intended route',
  intendedRoute2Description:
    'First country of transport by land / water / air after the product has left the Member State of production or the Member State of import, as determined by means of a control station at the national border, the nearest seaport or the nearest airport',
  countryCode: 'Country code',
  tpIdCaption: 'TP Identifier',
  tpPnCaption: 'TP Product Number',
  gtinCaption: 'GTIN',
  cnCodeCaption: 'Product CN code',
  cnCodeDescription: 'Product combined nomenclature code',
  customerOrderIdCaption: 'Your purchase order number',
  customerOrderIdDescription: 'The order number assigned by ID Issuer to your UI order.',
  productTypeCaption: 'Tobacco product type',
  productTypeDescription: 'Tobacco product type',
  productOtherTypeCaption: 'Other type',
  productOtherTypeDescription: 'Other type',
  productType_1: 'Cigarette',
  productType_2: 'Cigar',
  productType_3: 'Cigarillo',
  productType_4: 'Roll your own tobacco',
  productType_5: 'Pipe tobacco',
  productType_6: 'Waterpipe tobacco',
  productType_7: 'Oral tobacco',
  productType_8: 'Nasal tobacco',
  productType_9: 'Chewing tobacco',
  productType_10: 'Novel tobacco product',
  productType_11: 'Other',
  deliveryMethodCaption: 'Delivery method',
  deliveryMethodDescription:
    'Choose the delivery method used to ship UIs. Possible value is one of electronic or physical.',
  deliveryMethod_1: 'Electronically',
  deliveryMethod_2: 'Physically',
  buendeOrderIdCaption: 'Buende order ID',
  buendeorderIdDescription: 'Buende order ID',
  orderPositionCaption: 'Position',
  orderPositionDescription: 'Order position',
  orderAmountCaption: 'Quantity',
  orderAmountDescription: 'Order amount',
  importCaption: 'Import',
  importDescription: 'Choose whether the tobacco product will be produced outside the EU.',
  import_1: 'NO',
  import_2: 'YES',
  reqQuantityUpuiDescription: 'Requested quantity of individual identifiers at the unit level',
  binderValidatorIntendedMarket: 'Invalid intended market.',
  binderValidatorIntendedRoute: 'Invalid intended route.',
  //+++++ RECALL ORDER:
  orderIdCaption: 'Order ID',
  recallCaption: 'Recall',
  recallOrdersCaption: 'Recall Order',
  recallCodeCaption: 'Recall code',
  recallReasonCaption: 'Recall reason',
  recallReasonWarning: 'Recall reason must be between 1 and 50 characters long.',
  recallReason_1: 'Reported event did not materialise',
  recallReason_2: 'Message contained erroneous information',
  recallReason_3: 'Other',

  upOrderCaption: 'Up order',
  upOrderDescription: 'UI order',
  upOrderValueCaption: 'UI order data',
  upOrderValueDescription: 'UI order data',
  upUIOrderAnnounceButton: 'Get order details.',
  upUIOrderWarningNoInput: 'Please insert Buende Order ID.',
  upUIOrderWarningWrongInput: 'Could not find announced order with Buende ID:',
  upUIOrderWarningClickItem: 'Please select order position.',
  upUIOrderWarningAnnounceOrder: "Please click 'Get order details' button.",

  orderProcessingDescription:
    'Defines, if order shall be processed standard mode (after recall period elapsed) or immediate mode (order is immediately processed and delivered) no recall possible in this case).',
  listOrdersButtonCaption: 'List orders',

  //++++++++++ RETAILER OPERATIONS:
  myRetailers: 'My Retailers',
  noOfRetailers: 'Managed retailers',
  retailerData: 'Managed retailer data',
  registerEOButtonCaption: 'Register economic operator',
  updateEOButtonCaption: 'Economic operator correction',
  registerEO3rdPartyFormCaption: 'Economic operator 3rd party registration',
  correctionEO3rdPartyCaption: 'Economic operator 3rd party correction',
  correctionEOCaption: 'Economic operator correction',
  retailersFacilities: 'facilities',

  // Validation
  //++++++++++THIS PART IS COPIED FROM Vaadin/Core/PatternStrings file
  binderValidatorEoIdentifier: 'Economic Operator identifier must be between 1 and 50 characters long.',
  binderValidatorConfirmationCode: 'Confirmation code must be between 1 and 20 characters long.',
  binderValidatorFid: 'Facility identifier must be between 1 and 50 characters long.',
  binderValidatorMid: 'Machine identifier must be between 1 and 50 characters long.',
  otherIdsValidator: 'Identifier must be between 1 and 50 characters long.',
  //++++++++++
  binderValidatorName: 'Operator name must be between 1 and 100 characters long.',
  binderValidatorEoEmail: 'Email must be between 5 and 80 characters long.',
  binderValidatorMobilePhone: 'Invalid mobile phone.',
  binderValidatorEoExciseNumber:
    "Excise number must be composed of a country name coded with ISO-3166-1:2013 alpha-2 (or its latest equivalent) (e.g. 'LU') and eleven alphanumeric characters, if needed, padded to the left with zeroes (e.g. '00000987ABC').",
  binderValidatorHouseNumber: 'House number should have at least one number.',
  binderValidatorHouseNumberInvalidForGermany: 'House numbers shall consist of the following symbols only: digits, characters, space, special characters - /',
  binderValidatorCountryReg: 'Invalid ISO Alpha-2 country code.',
  binderValidatorTpPn: 'Tobacco product number consists of 1-30 characters.',
  binderValidatorPCn:
    'Product CN must be 4|7|10 chars long. First 4 places are digits. eg. 1234 | 1234 12 | 1234 12 34',
  binderValidatorVat: 'Invalid VAT number format! First two characters must be Alpha-2 Country Code.',
  binderValidatorTin: 'Invalid Tax Identification Number format!',
  VatCountryCode: 'Wrong format. A VAT-No. starts with a 2 character country code followed by a max. of 12 additional characters/digits',
  NonGermanVatGermanAddress: 'For companies based in Germany, the VAT ID must start with "DE" followed by nine digits',
  otherIdsSizeValidator: 'You can add maximum 10 IDs.',
  binderValidatorCapacity: 'Only numbers are allowed!',
  binderValidatorWeight: "Weight must be a number with '.' used as a separator if the number is decimal.",
  binderValidatorBuendeId: 'Buende order ID must be between 1 and 35 characters long.',
  binderValidatorOrderPosition: 'Order position must be a number.',
  bibinderValidatorTpId: "Tobacco product ID consists of numbers and dashes in format 'NNNNN-NN-NNNNN'.",
  binderValidatorGtin: 'GTIN consists of 14 numbers.',

  //Orders
  ordersSearch: 'Search',
  ordersNewUpUiOrder: 'New upUI order',
  ordersNewAUiOrder: 'New aUI order',
  downloadButtonEnabledCaption: 'Download generated IDs',
  downloadButtonDisabledCaption: 'Orders download will be enabled after processing is finished',
  ordersSearchFrom: 'From',
  ordersSearchTo: 'To',
  ordersSearchId: 'Please insert ID',

  //Search
  facilitySearch: 'Search by FID, Type, City, Street',
  machineSearch: 'Search by MID, FID, Model, Producer, Serial Number',
  managedRetailerSearch: 'Search by EOID, Name, City, Street',

  //++++++++++ ORDERS WARRNING MESSAGES
  ReqQuantityNull: 'Correct Request Quantity must be provided.',
  ReqQuantityMin: 'Quantity minimal value is 1.',
  ReqQuantityMax: 'Quantity maximal value is 1000000.',
  NotificationWebHookBaseSize: 'Size of notification web hook base must be max 250 characters long.',
  ProcessTypeNull: 'Process type must be provided.',
  TobaccoProductNull: 'Tobacco product must be provided.',
  ProductWeightNull: 'Product weight must be provided.',
  ProductWeight: 'Package weight must be equal or greater then 0.1 grams.',
  IntendedMarketNull: 'Intended market country code must be provided.',
  IsAcrossBorderNull: 'Intended Route must be provided.',
  IsImportedNull: 'Import value must be provided.',
  DeliveryMethodNull: 'Delivery method must be provided.',
  BuendeOrderIdSize: 'Buende order size is at max 35 characters long.',
  TobaccoProductBrandNull: 'Tobacco product brand must be provided.',
  TobaccoProductBrandSize: 'Tobacco product brand can be maximum 200 chars long.',
  ProductBrandIdNull: 'tpId, Tobacco product ID must be provided.',
  ProductBrandIdInvalid: 'TpId must be in NNNNN\u2013NN\u2013NNNNN format eg. 12345-12-12345.',
  ProductBrandNumberNull: 'TpPn, Tobacco product number must be provided.',
  ProductBrandNumberInvalid: 'TpPn must be characters length between 1 and 30.',
  GTINInvalid: 'GTIN is a 8, 12, 13 or 14 digit value.',
  CnCodeInvalid: 'PCN must consist of min. 8 and max. 10 digits only',
  BuendeOrderIdNotAnnounced: ' Buende order ID is not announced.',
  BuendeOrderPosDifferent: ' Buende order position mismatch.',
  OrderDeleteInvalidRecallReason1: 'Invalid recall reason.',
  AnnouncementOrderDoesntExists: 'Announcement order does not exists.',
  CustomerOrderIdSize: 'Customer order Id size is at max 23 characters long',
  //+++++UpUi orders
  TobaccoProductIdNotAlignedWithCountry: 'Tobacco product ID mismatch.',
  TobaccoProductNumberNotAlignedWithCountry: 'Tobacco product number mismatch.',
  ProductTypeOtherNotAlignedWithProductType: 'Tobacco type other mismatch.',
  BuendeOrderPosNotAlignedWithDeliveryMethod: 'Buende order position mismatch.',
  BuendeOrderIdNotAlignedWithDeliveryMethod: 'Buende order ID mismatch.',
  AcrossBorderNotAlignedWithIsAcrossBorder: 'Intended route 2 mismatch.',
  MachineIdNotAlignedWithProcessType: 'Machine ID is not aligned with process type.',
  EoidNotAlignedWithActingOnBehalf: 'EoId is not aligned with Managed retailer.',
  UpuiOtherProductTypeSize: 'Other product type max 50 chars long.',

  //+++++ Errors (exception messages)
  errorWrongConfirmationCode: 'Wrong confirmation code!',
  errorInvalidData: 'Entered data is invalid!',

  //+++++API ERRORS
  EmptyMessageFromServer: 'Something went wrong with site. Please contact support.',

  //+++++ SERVER ERRORS
  ServerError: 'Server Error 500.',
  DatabaseError: 'Database Error.',

  //+++++ OTHER ERRORS
  EoCodeIdentityNotConfirmed: 'Identity not confirmed. EO code mismatch.',
  EoCode3rdPartyIdentityNotConfirmed: 'Facility EoId mismatch.',
  AllOtherErrors: ' Please check input values and try again or restart this action.',

  //+++++ TOKEN & PATH
  TokenEoidInvalid: 'Invalid token EoId.',
  TokenEoidEmpty: 'Invalid token EoId.',
  EoIdTokenMismatchReg3rd: 'EoId token mismatch.',
  EoIdTokenMismatch: 'EoId token mismatch.',
  EoIdPathTokenMismatch: 'EoId token mismatch.',

  //+++++ OPERATOR
  OperationWithDeactivatedOperator: 'Operator does not exist.',
  OperatorDoesNotExistWithEoid: 'Operator does not exist.',
  OperatorEoidTokenDoesNotMatchFacilityEoId: 'Invalid token EoId.',
  OperatorUberDoesNotExistWithEoid: 'Operator does not exist.',

  OperatorAddressInvalidInvoiceEmail: 'Invalid invoice Email.',
  OperatorAddressInvalidInvoiceAddress: 'Invalid invoice Address.',
  OperatorRetailerEoidTokenEoidMismatch: 'EoId token mismatch.',
  OperatorEoidTokenEoidMismatch: 'EoId token mismatch.',


  //+++++ Operator fields
  OnBehalfNull: 'Managed retailer must be provided.',
  OnBehalfEoIdNull: 'Managed retailer must be provided.',
  OperatorInvoiceAddressNull: 'Invoice address must be provided.',
  OperatorNameNull: 'Operators name must be provided.',
  OperatorNameSize: 'Operator name size must be between 1 and 100 characters long.',
  OperatorNameAltSize: 'Operator alt name size must be between 1 and 100 characters long.',

  TinTaxFormat: 'Wrong Tin Tax format (vat R).',
  GermanVatNonGermanAdress: 'A German VAT ID is not allowed for non-German company addresses',
  InvalidVat: 'Invalid Vat. Vat can have size between 3 and 20 characters.',
  VatNumberSize: 'Vat can have size between 8 and 14 characters.',
  TinNumberSize: 'Tin can have size between 8 and 16 characters.',
  SeedExistsNull: 'EoExciseNumber1 must be provided.',
  TinTaxFormatNull: 'Wrong TinTax format.',
  TinTaxFormatNotAligned: 'Wrong TinTax format.',
  EoTypeMax: 'Max value for Eo Type is 1.',
  EoTypeMin: 'Min value for Eo Type is 0.',
  EoIdGenerationProblem: 'Problem with eoId generation.',
  InvalidIssuerCountry: 'Invalid Issuer Country.',
  ProblemWithEoCodeGeneration: 'Fail to generate EO code.',
  GeneratedEoidInvalid: 'Fail to generate EO code.',
  OperatorsDbRetailerEoidMissmatchToken: 'EoId token mismatch.',
  GeneratorIssuerIdEmpty: 'Issuer ID must not be empty.',
  GeneratorCodeVersionEmpty: 'Code version must not be empty.',
  GeneratorCountryCodeEmpty: 'Country code must not be empty.',
  //+++++Eoids fields
  OtherEoidsExistsNull: 'Other EoIds must be provided.',
  OtherEoidsSize: 'Only 10 other EoIds are allowed.',
  OtherEoidsNotAligned: 'Economic operator identifier must be between 1 and 50 characters long.',
  OtherEoidsAlreadyExists: 'This EoId already added.',

  //+++++ Onboarding
  InvoiceLanguageNotAlignedEoType: 'Invalid invoice language.',
  InvoiceCompanyNameNotAlignedEoType: 'Invalid invoice company name.',
  InvoiceAddressNotAlignedEoType: 'Invalid invoice address.',
  InvoicingCountryNotAlignedEoType: 'Invalid invoice country.',
  InvoicingEmailNotAlignedEoType: 'Invalid invoice Email.',
  InvalidInvoicingLanguageKey: 'Invalid invoice language code.',
  AccreditedEoidNotAlignedNotNull: 'EoId token mismatch.',
  AccreditedEoidNotAlignedNull: 'EoId token mismatch.',
  InvoiceStreetSize: 'Invoice street size must be at max 40 chars long.',
  InvoiceHouseNumberSize: 'Invoice House number size must be at max 20 characters long.',
  InvoiceAddressZipCodeSize: 'Invoice ZIP code must be between 1 and 10 characters long.',
  InvoiceCitySize: 'Invoice City size must be between 1 and 40 characters long.',
  InvoiceCountryCodeInvalid: 'Invalid Invoice ISO Alpha-2 country code.',

  AccreditedEoidRegistration: 'The provided EO-ID was issued by the German ID Issuer. You must not use this EO-ID for a registration as "Accredited external operator".',
  InvoiceEmailInvalidSize: 'Invoice Email size must be between 5 and 80 characters long.',
  mobilePhoneNull: 'Telephone number must be provided.',


  //+++++Operations
  selectFilter: 'Please select',
  idissuerOrderIdCaption: 'IDIssuer order ID',
  customerOrderId: 'Customer order ID',
  eoRegistrationDataCaption: 'EO registration data',
  eoInvoiceDataCaption: 'EO invoice data',

  //+++++ FACILITY
  FacilityDoesNotExistWithFid: 'Facility does not exist.',
  FacilityDeactivatedOperation: 'Facility does not exist.',
  FacilityEoidRequestEoid: 'EoId token mismatch.',
  //+++++Facilities fields
  FacilityTypeNull: 'Facility type must be provided.',
  FacilityOrderStatusNull: 'Facility order status must be provided.',
  FacilityExerciseNumberNull: 'Exercise number must be provided.',
  //+++++Facilities token
  FacilityTokenMismatchReg3rd: 'Facility EoId token mismatch.',
  //+++++eoid mismatch
  FacilityTokenMismatch: 'EoId token mismatch.',
  //+++++Fids fields
  OtherFidsExistsNull: 'Other FIds exists must be provided.',
  OtherFidsSize: 'Only 10 other FIds are allowed.',
  OtherFidsElementSize: 'Facility identifier must be between 1 and 50 characters long.',
  OtherFidsAlreadyExists: 'This FId already added.',

  //++++++++++MACHINES:
  MachineWithSerialNumberExists: 'Machine with serial number already exists.',
  MachineDoesNotExistMid: 'Machine does not exist.',
  MachinesDeactivatedOperation: 'Machine does not exist.',
  //++++++++++Machines fields:
  MachineProducerNull: 'Machine producer must be provided.',
  MachinesProducerSizeInvalid: 'Machine producer length must be between 1 and 20.',
  MachinesModelNull: 'Machine model must be provided.',
  MachinesModelSizeInvalid: 'Machine model size must be between 1 and 20',
  MachinesSerialNumberNull: 'Machine serial number must be provided.',
  MachinesSerialNumberSizeInvalid: 'Machine serial number must be between 1 and 20.',
  MachinesCapacityNull: 'Machine capacity must be provided.',
  MachinesCapacityMin: 'Minimum capacity is 1.',
  MachinesCapacityMax: 'Maximum capacity is 999999.',
  MachinesCapacityMaximum: 'Maximum capacity is 2147483647.',
  GiaiSize: 'GIAI must be 30 characters long.',

  //+++++ CONDITIONAL FIELDS
  NotAlignedEoidActingOnBehalf: 'Retailer EoId mismatch.',
  NotAlignedSeedNumber: 'Seed number mismatch.',
  NotAlignedOtherFids: 'Other Fids mismatch.',
  NotAlignedOtherTypeDiscription: 'Facility type other mismatch.',

  //+++++FIELDS
  EmailInvalid: 'Invalid Email.',
  EmailSize: 'Email must be between 5 and 80 characters long.',
  EmailNull: 'Email must be provided.',
  EmailInvalidSize: 'Email size must be between 5 and 80 characters long.',


  //+++++ IDENTIFIER REGEX
  EoIdInvalid: 'EOID must be between 1 and 50 characters long.',
  EoIdNull: 'Eoid must be provided.',
  FidInvalid: 'FID must be between 1 and 50 characters long.',
  FidNull: 'Fid must be provided.',
  MidInvalid: 'MID must be between 1 and 50 characters long.',
  MidNull: 'Mid must be provided.',
  EoCodeIdInvalid: 'EoCode must be between 1 and 20 characters long.',
  EoCodeNull: 'Eo code must be provided.',

  //++++++++++ ADDRESS
  AddressNull: 'Address must be provided.',
  ZipCodeInvalidCountry: 'Invalid zip code for given country.',
  AddressZipCodeNull: 'ZIP code must be provided.',
  AddressHouseNumberNotAlignedWithFacilityType: 'Address house number mismatch.',
  AddressStreetNotAlignedWithFacilityType: 'Address street number mismatch.',

  StreetNull: 'Street must be provided.',
  StreetSize: 'Street size must be between 1 and 40 characters long.',
  HouseNumberNull: 'House number must be provided.',
  HouseNumberSize: 'House number size must be between 1 and 20 characters long.',
  HouseNumberInvalid: 'House number should have at least one number.',
  HouseNumberCharacters: 'House numbers shall consist of the following symbols only: digits, characters, space, special characters - /',
  ISO8859: 'The entered input is not compliant with the ISO8859-15 standard',
  CityNull: 'City must be provided.',
  CitySize: 'City size must be between 1 and 40.',
  ZipCodeSize: 'Zip code must be at max 10 characters long',
  AddInfoSize: 'Additional address information must be at max 50 characters long.',
  AddressZipCodeSize: 'Zip code must be at max 10 characters long.',
  wrongGermanZipCodeFormat: 'German zip codes consists of 5 digits.',

  //+++++ COUNTRY
  CountryNull: 'Country must be provided.',
  CountryCodeInvalid: 'Invalid country code.',
  CountryRegInvalid: 'Country registration must be provided.',

  //+++++ correction
  mobilePhoneSize: 'Mobile phone size can be max 20 chars long.',
  mobilePhoneFormat: 'Malformed cell-phone number.',
  steuernummerFormat: 'The Steuernummer is malformed',
  InvoicingEmailNotAligned3rd: 'Invoicing email mismatch.',
  InvoicingAddressNotAligned3rd: 'Invoicing address mismatch.',
  InvoicingCountryNotAligned3rd: 'Invoicing country mismatch.',
  invoicingCompanyNotAligned3rd: 'Invoicing company mismatch.',
  invoicingLanguageNotAlignedEoType: 'Invoicing language mismatch.',

  //+++++ SORT
  SortBySortOrderNotAligned: 'Start and size must be set.',
  UnsupportedSortOrder: 'Unsupported sortion.',
  StartListSizeNotAligned: 'Both or none params (start, size) must be set.',

  //+++++ KEYCLOAK
  UnableToDisableKeyCloakUser: 'Keycloack is unable to disable user.',

  //+++++ Unused
  EoTypeMustBeProvided: 'Eo type must be provided.',
  InvoicingLanguageNull: 'Invoice language is empty.',

  //+++++ Secondary Repository
  SecRepoServiceCommunicationFail: 'Unable to contact secondary repository service.',
  SecRepoFailedToSubmitData: 'Failed to submit record to secondary repository.',
  SecRepoDataValidationFail: 'ID does not exist in secondary repository.',
  SecondaryRepoExternalCommunicationError: 'External communication error from secondary repository.',
  SecondaryRepoInvalidRequest: 'Unable to contact secondary repository service.',
  SecondaryRepoUnknownError: 'Unknown error from secondary repository.',
  RestClientExceptionSecondRepo: 'Unable to contact secondary repository service.',
  SecondaryRepoServiceAccessDenied: 'Unable to contact secondary repository service.',

  //++++++++++ 24.4
  AcrossBorderCountryMustBeProvided: 'Intended route must be provided.',
  BuendeOrderMax: 'BuendeOrderPos can be max 99.',
  BuendeOrderMin: 'BuendeOrderPos can be min 1.',
  BuendeOrderId: 'BuendeOrderId can be max 35.',
  BuendeOrderNotNull: 'BuendeOrderId must be provided.',
  AnnouncementQuantityMin: 'Quantity minimum value must be at least 1.',
  AnnouncementQuantityMax: 'Quantity max can be max 1000000.',
  AnnouncementSapOrderMax: 'sapOrderId max length can be maximum 10 characters long.',
  AnnouncementSapOrderNotNull: 'sapOrderId must be provided.',
  OtherTypeDescriptionSize: 'Description can be maximum 50 characters long.',

  //+++++25.4
  EoidTaken: 'Accredited EO-Id already in Database.',
  //+++++30.4
  AccreditedOnlyDeIntendedMarket: 'Intended route must be DE.',
  //+++++20.5
  InvalidIntendedMarket: 'Invalid intended market.',
  InvalidFacilityCountryGermanIdIssuer:
    ' Invalid address. Address must reference a location on the territory of Germany.',
  //+++++21.5
  ProcessingError: 'Process failed. Something went wrong.',
  //+++++11.6
  InvalidAddressCountryGermanIdIssuer:
    'Invalid address. Address must reference a location on the territory of Germany.',
  //+++++12.12
  OrderCancelPeriodElapsed: 'Order cancellation period elapsed.',
  //+++++6.4.20
  InvalidISO885915Char: 'String contains invalid ISO885915 char',

  //+++++++SMSCode
  SMSOnly4Digits: 'SMS code must consist of exactly 4 only digits.',

  //+++++++Review Page
  ReviewData: 'Please review your data and proceed with the onboarding process.',

  EconomicOperator: 'Economic Operator',
  EconomicOperatorType: 'Economic operator type',

  //++++++++++Countries
  AUSTRIA: 'AUSTRIA',
  BELGIUM: 'BELGIUM',
  BULGARIA: 'BULGARIA',
  CROATIA: 'CROATIA',
  CYPRUS: 'CYPRUS',
  CZECH_REPUBLIC: 'CZECH REPUBLIC',
  DENMARK: 'DENMARK',
  ESTONIA: 'ESTONIA',
  FINLAND: 'FINLAND',
  FRANCE: 'FRANCE',
  GERMANY: 'GERMANY',
  GREECE: 'GREECE',
  HUNGARY: 'HUNGARY',
  IRELAND: 'IRELAND',
  ITALY: 'ITALY',
  LATVIA: 'LATVIA',
  LITHUANIA: 'LITHUANIA',
  LUXEMBOURG: 'LUXEMBOURG',
  MALTA: 'MALTA',
  NETHERLANDS: 'NETHERLANDS',
  POLAND: 'POLAND',
  PORTUGAL: 'PORTUGAL',
  ROMANIA: 'ROMANIA',
  SLOVAKIA: 'SLOVAKIA',
  SLOVENIA: 'SLOVENIA',
  SPAIN: 'SPAIN',
  SWEDEN: 'SWEDEN',
  UNITED_KINGDOM: 'UNITED KINGDOM',
  NONEU: 'NONEU',
  INTERNATIONAL_WATERS: 'INTERNATIONAL WATERS',
}

export default messages_en
